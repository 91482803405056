<section class="confirm" [class.modal--active]="active">
  <div class="confirm__wrapper">
    <div class="confirm__wrapper__box">
        <header class="confirm__wrapper__box__header">
          <h1 class="confirm__wrapper__box__header__title" *ngIf="message.title"> {{message.title}}</h1>
          <p class="confirm__wrapper__box__header__text" *ngIf="message.text">{{message.text}}</p>
        </header>
        <div class="button-group">
          <button class="button type--primary-inverted" (click)="confirmHandler(false)">Nee</button>
          <button class="button type--primary" (click)="confirmHandler(true)">Ja</button>
        </div>
    </div>
  </div>

</section>


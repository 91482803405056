import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scrollTo]'
})
export class ScrollToDirective {
  @Input() scrollTo = 0;
  @HostListener('click')
  scrollToY(scrollTargetY: number = this.scrollTo, speed: number = 2000, easing: string = 'easeOutSine') {
    scrollToY(this.scrollTo, speed, easing);
  }

  constructor() { }
}

export function scrollToY(scrollTargetY: number = 0, speed: number = 2000, easing: string = 'easeOutSine') {
  let currentTime = 0;

  const scrollY = window.scrollY;
  const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));
  const PI_D2 = Math.PI / 2;
  const easingEquations = {
    easeOutSine: pos => Math.sin(pos * (Math.PI / 2)),
    easeInOutSine: pos => (-0.5 * (Math.cos(Math.PI * pos) - 1)),
    easeInOutQuint: pos => {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2);
    }
  };

  function tick() {
    currentTime += 1 / 60;
    const p = currentTime / time;
    const t = easingEquations[easing](p);

    if (p < 1) {
      requestAnimationFrame(tick);
      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      // console.log('scroll done');
      window.scrollTo(0, scrollTargetY);
    }

  }
  tick();
}

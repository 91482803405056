import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { MagazineEditor } from '../models/magazine-editor';
import { LoginData } from '../models/login-data';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  getAuth(): Observable<MagazineEditor> {
    return this.http.get<MagazineEditor>('/api/auth/user');
  }

  login(loginObj: LoginData) {
    return this.http.post<any>('/api/auth/signin', loginObj);
  }

  signOut() {
    return this.http.post<any>('/api/auth/logout', {});
  }
}

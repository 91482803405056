import { createReducer, on } from '@ngrx/store';
import * as ContrastActions from '../actions/contrast.action';

// TODO manage themes and contrast in same state
export interface ContrastState {
  highContrast: boolean;
}

export const initialState: ContrastState = {
  highContrast: false,
};

export const ContrastReducer = createReducer(
  initialState,
  on(ContrastActions.EnableHighContrast, state => {
    return {
      ...state,
      highContrast: true,
    };
  }),
  on(ContrastActions.DisableHighContrast, state => {
    return {
      ...state,
      highContrast: false,
    };
  }),
  on(ContrastActions.ToggleHighContrast, state => {
    return {
      ...state,
      highContrast: !state.highContrast,
    };
  })
);

export const getHighContrast = (state: ContrastState) => state.highContrast;

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringJoin'
})
export class StringJoinPipe implements PipeTransform {

  transform(value: any[], field: string, separator: string = ', '): string {
    return value.map(a => a[field]).join(separator);
  }

}

<footer class="footer article-footer color--white" [ngClass]="'article--'+typeArticle">
  <div class="article-footer__container">
    <div class="footer__donate" *ngIf="('footer-call2action' | fromDictionary) ==='true'">
      <p class="subtitle" [innerHTML]="'footer-call2action-text' | fromDictionary"></p>
      <span [innerHTML]="'footer-call2action-link' | fromDictionary"></span>
    </div>
    <section class="article-footer__content">
      <a href="https://www.stofwisselingsziekten.nl/" target="_blank" class="footer__logo"></a>
      <ul class="article-footer__row">
        <li class="article-footer__row__item">{{'footer_copyright' | fromDictionary}}</li>
        <li class="article-footer__row__item">{{'footer_rights' | fromDictionary}}</li>
        <li class="article-footer__row__item"><a href="mailto:info@stofwisselingsziekten.nl">{{'footer_email' | fromDictionary}}</a></li>
        <li class="article-footer__row__item"><a [href]="'footer-privacy-link' | fromDictionary">Privacy</a></li>
      </ul>

    </section>

  </div>
</footer>

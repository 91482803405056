<article class="teaser {{type}}">
  <figure class="teaser__crop {{type}}__crop" *ngIf="data.iconUrl || data.img">
    <img [src]="data.iconUrl || data.img" alt="" />
  </figure>

  <div class="teaser__content {{type}}__content">
    <header class="teaser__content__header  {{type}}__content__header">
      <h1 class="{{type}}__content__header__title" >{{data.title}}</h1>
      <h2 class="{{type}}__content__header__subtitle" >{{data.subtitle}}</h2>
    </header>
    <div class="{{type}}__content__text" *ngIf="data.text">
      {{data.text | stripHtml | truncate: 140}}
    </div>
  </div>

  <ul class="{{type}}__meta" *ngIf="data.meta">
    <li class="{{type}}__meta__item type--category">
      <span  *ngIf="data.meta.category">{{data.meta.category}}</span>
      <app-icon [anchor]="'icon-arrow-right'"></app-icon>
    </li>
    <li class="{{type}}__meta__item type--date">
      <span  *ngIf="data.meta.date">{{data.meta.date | date: 'dd MMMM yyyy'}}</span>
    </li>
  </ul>

  <a [routerLink]="[data.url]" class="block-link {{type}}__button" *ngIf="data.url">
    meer over {{data.title}}
  </a>

</article>

<section class="breadcrumbs">
  <h2 class="sr-only">Kruimelpad</h2>

  <a class="button-link button--muted" [routerLink]="['/']">
    <app-icon [anchor]="'icon-arrow-left'"></app-icon>
    <span>Terug</span>
  </a>

  <div class="vr"></div>

  <ul class="breadcrumb-list">
    <li class="breadcrumb-list__item">
      <a class="button-link button--muted" [routerLink]="['/']">Home</a>
    </li>
    <li class="breadcrumb-list__item">
      <a
        class="button-link button--primary is--disabled"
        [routerLink]="['/']"
        >{{ currentPageTitle }}</a
      >
    </li>
  </ul>
</section>

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { registerLocaleData } from "@angular/common";
import localeNl from "./nl";
import { MagazineNavService } from "./magazine/services/magazinenav.service";
import { MaterialModule } from "./material.module";
import {
  DictionaryConfig,
  DictionaryNgrxModule,
} from "@teamfoster/sdk/dictionary-ngrx";
import { StoreModule } from "@ngrx/store";
import { CustomSerializer, appEffects, metaReducers, reducers } from "./store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";

registerLocaleData(localeNl, "nl");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    CoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    DictionaryNgrxModule.forRoot({}),
  ],
  exports: [MaterialModule, BrowserAnimationsModule],
  providers: [{ provide: LOCALE_ID, useValue: "nl" }, MagazineNavService],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { first, map, tap } from 'rxjs/operators';

import { isPlatformServer, DOCUMENT } from '@angular/common';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const editModeActive = route.queryParams.edit;

    if (isPlatformServer(this.platformId) && !editModeActive) {
      return of(true);
    }

    return this.authService.getAuth().pipe(
      first(),
      tap(a => {
        if (!a.auth && !editModeActive) {
          return;
        } else if (!a.auth) {
          this.router.navigate([`/`, `beheer`], {
            queryParams: { url: state.url },
          });
        } else if (a.auth && !editModeActive) {
          this.router.navigate([state.url], {
            queryParams: { edit: true },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
        }
      }),
      map(a => (a.auth || false || !editModeActive ? true : a.auth || false))
    );
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId
  ) {}
}

import { Injectable } from '@angular/core';

@Injectable()
export class ShareService {

  constructor() { }

  getLinks(url: string) {
    return [
      {icon: 'icon-facebook', iconType: 'fab', title: 'Facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${url}`},
      {icon: 'icon-twitter', iconType: 'fab', title: 'Twitter', target: '_blank', url: `https://twitter.com/intent/tweet?text=${url}`},
      {icon: 'icon-linkedin', iconType: 'fab', title: 'LinkedIn', url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`},
    ];
  }
}

import { Component, OnInit, Inject, PLATFORM_ID, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { AuthService } from "./auth/services/auth.service";
import { MagazineEditor } from "./auth/models/magazine-editor";
import { MagazineNavService } from "./magazine/services/magazinenav.service";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import { getDictionaryLoaded } from "@teamfoster/sdk/dictionary-ngrx";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  private store = inject(Store);
  loading$ = new BehaviorSubject<boolean>(true);
  loaded$: Observable<boolean> = this.store.select(getDictionaryLoaded);

  title = "app";
  auth: MagazineEditor;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private authService: AuthService,
    private magazineService: MagazineNavService,
    private t: Title
  ) {
    this.authService.getAuth().subscribe((a) => {
      this.auth = a;
      // console.log(this.auth);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loading$.next(false);
      }
      if (
        isPlatformBrowser(this.platformId) &&
        event instanceof NavigationEnd
      ) {
        (<any>window).gtag("config", "UA-138171483-1", {
          page_path: event.urlAfterRedirects,
          anonymize_ip: true,
        });
      }
    });
  }

  ngOnInit(): void {
    this.magazineService.setShowNav(false);

    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector("body").classList.add("set--in");
    }
  }

  signOut() {
    this.authService.signOut().subscribe((a) => {
      window.document.location.href = "/";
    });
  }
}

<header class="header {{cssClass}}" [class.is--inview]="isVisible"  [delay]="10" (appInViewport)="isVisible = true">
  <figure class="header__crop" *ngIf="bgImage || videoBg">

    <div class="header__crop__container" *ngIf="!videoBg">
      <img class="header__crop__image" [src]="bgImage+'?width=1900&format=jpg&ranchor=topright'" />
    </div>
    <app-htmlvideo
      class="header__crop__video"
      *ngIf="videoBg"
      [posterSrc]="bgImage+'?maxwidth=1800&format=jpg&ranchor=topright'"
      [videoSrc]="videoBg">
    </app-htmlvideo>
  </figure>
  <div class="header__content" [class.set--absolute]="bgImage || videoBg">
      <header class="header__content__header">
        <strong class="header__content__type" *ngIf="type && !subtitle">{{type}}</strong>
        <h2 class="h5 subtitle header__content__subtitle" *ngIf="subtitle">{{subtitle}}</h2>
        <h1 class="h2 header__content__title" *ngIf="title">{{title}}</h1>
      </header>

    <div class="header__content__intro block__text container type--s"  *ngIf="introtext" [innerHTML]="introtext"></div>
  </div>
</header>

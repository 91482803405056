<div class="topbar" [class.set--nav]="showNav">
    <div class="topbar__logo-block bg--white" id="TopbarLogo">
        <a (click)="historyBack()" class="topbar__back" *ngIf="showBack"><app-icon anchor="icon-chevron-right"></app-icon> Terug</a>
        <h1 class="topbar__logo">
            <a [routerLink]="'/'">
              VKS Wisselstof
            </a>
        </h1>
        <div class="button-group topbar__buttons">
            <button [routerLink]="['/magazine/zoeken/']" class="button type--icon-only " *ngIf="searchActive"><app-icon [anchor]="'icon-search'"></app-icon></button>
            <button *ngIf="auth?.auth" type="button" class="button type--icon signout " (click)="signOut.emit(true)"><app-icon [anchor]="'icon-signout'"></app-icon><span>Uitloggen</span></button>
        </div>
    </div>



    <div class="topbar__magazine-nav" *ngIf="showNav">
        <nav class="topbar__nav">
            <div class="topbar__nav__toggle" *ngIf="false">
              <button class="topbar__nav__toggle__button" [class.is--active]="menuActive" (click)="toggle()">
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
              </button>
            </div>

            <ul class="topbar__nav__list-sidenav" *ngIf="false">
                <li class="topbar__nav__list-sidenav__item" [routerLinkActive]="'is--current'" [routerLinkActiveOptions]="{exact:true}"><a [routerLink]="['/']" (click)="toggle()">Home</a></li>
                <li class="topbar__nav__list-sidenav__item" [routerLinkActive]="'is--current'"><a [routerLink]="['/magazine/']" (click)="toggle()">Magazines</a></li>
                <li class="topbar__nav__list-sidenav__item" [routerLinkActive]="'is--current'"><a [routerLink]="['/style-guide']" (click)="toggle()">styleguide</a></li>
            </ul>

            <button class="topbar__nav__toggle__button" [class.is--active]="menuActive" [routerLink]="['/magazine/']" *ngIf="true" >
              <app-icon [anchor]="'icon-home'"></app-icon>
            </button>
        </nav>
    </div>

</div>

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/guards/auth-guard.guard";
import { DictionaryGuard } from "@teamfoster/sdk/dictionary-ngrx";

const appGuards = [DictionaryGuard];

const routes: Routes = [
  {
    path: "",
    // loadChildren: 'app/home/home.module#HomeModule',
    pathMatch: "full",
    redirectTo: "magazine",
  },
  {
    path: "magazine",
    loadChildren: () =>
      import("./magazine/magazine.module").then((m) => m.MagazineModule),
    canActivate: [...appGuards],
    canActivateChild: [AuthGuard],
  },
  {
    path: "beheer",
    canActivate: [...appGuards],
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "style-guide",
    loadChildren: () =>
      import("./style-guide/style-guide.module").then((m) => m.StyleGuideModule),
    canActivate: [...appGuards],

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

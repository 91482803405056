import {
  Component,
  OnInit,
  Input,
  Renderer2,
  Inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';

import { MagazineNavService } from '../../../magazine/services/magazinenav.service';
import { MagazineEditor } from '../../../auth/models/magazine-editor';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  @Input() loading = false;
  @Input() auth: MagazineEditor;

  @Output() signOut = new EventEmitter<boolean>();

  menuActive = false;
  searchActive = true;
  showNav = true;
  showBack = false;

  constructor(
    @Inject(DOCUMENT) private document,
    private renderer: Renderer2,
    private magazineserve: MagazineNavService,
    private location: Location
  ) {}

  ngOnInit() {
    this.magazineserve.showSearch.subscribe(s => (this.searchActive = s));
    this.magazineserve.showNav.subscribe(n => (this.showNav = n));
    this.magazineserve.showBack.subscribe(n => (this.showBack = n));
  }

  toggle() {
    this.menuActive = !this.menuActive;
    this.updateRoot();
  }

  updateRoot() {
    this.menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  historyBack() {
    this.location.back();
  }
}

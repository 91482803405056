<nav class="social-share" >
    <h1 class="h6 subtitle social-share__title">{{shareTitle}}</h1>
    <ul class="social-share__list">
      <li class="social-share__list__item" *ngFor="let links of shareLinks;" [ngClass]="{'social-share__item--facebook':links.title === 'Facebook'}" >
        <!-- <a class="button type--icon-only" [ngClass]="links.title" [href]="links.url" target="_blank" rel="noopener" [title]="links.title"> -->
        <a class="button type--icon-only" [ngClass]="links.title" (click)="goToLink(links.url)" target="_blank" rel="noopener" [title]="links.title">
          <app-icon [anchor]="links.icon"></app-icon>
        </a>
      </li>
    </ul>
  </nav>

import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { Article } from '../models/article';

@Injectable()
export class MagazineNavService {

  currentArticle = new BehaviorSubject<Article>(null);
  showSearch = new BehaviorSubject<boolean>(true);
  showBack = new BehaviorSubject<boolean>(false);
  showNav = new BehaviorSubject<boolean>(true);
  constructor(
  ) { }

  setCurrentArticle(value) {
    // console.log(value);
    this.currentArticle.next(value);
  }

  setShowSearch(value) {
    this.showSearch.next(value);
  }

  setShowNav(value) {
    this.showNav.next(value);
  }

  setShowBack(value) {
    this.showBack.next(value);
  }
}

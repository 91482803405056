import { Component, OnInit } from '@angular/core';
import { MagazineNavService } from '../../../magazine/services/magazinenav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  typeArticle = null;

  constructor(
    private magazineNav: MagazineNavService
  ) { }

  ngOnInit() {
    this.magazineNav.currentArticle.subscribe(a => {
      // this.typeArticle = a.articleType.title.toLowerCase();
      if(a) {
        this.typeArticle = a.articleType ? a.articleType.title.toLowerCase() : null;
      } else {
        this.typeArticle = 'interview'
      }

    });
  }
}

import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params, Data } from '@angular/router';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromMeta from '../reducers/meta.reducer';
import * as fromNav from '../reducers/nav.reducer';
import * as fromContrast from '../reducers/contrast.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  meta: fromMeta.MetaState;
  nav: fromNav.NavState;
  contrast: fromContrast.ContrastState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  meta: fromMeta.metaDataReducer,
  nav: fromNav.NavReducer,
  contrast: fromContrast.ContrastReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');
export const getNavState = createFeatureSelector<fromNav.NavState>('nav');
export const getContrastState = createFeatureSelector<fromContrast.ContrastState>('contrast');

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }

    const { params } = state;
    const { data } = state;

    return {
      url,
      queryParams,
      params,
      data,
    };
  }
}

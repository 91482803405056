import { createAction, props } from "@ngrx/store";
import { MetaData } from "../../core/models";

export const SetTitle = createAction(
  "[Meta] Set meta title",
  props<{ title: string }>()
);
export const SetTags = createAction(
  "[Meta] Set meta tags",
  props<{ properties: { [property: string]: string } }>()
);
export const SetMetadata = createAction(
  "[Meta] Set meta data",
  props<{ data: MetaData }>()
);

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-indicator',
  templateUrl: './loading-indicator.component.html'
})
export class LoaderIndicatorComponent implements OnInit {
  @Input() loading = false;

  constructor() { }

  ngOnInit() {
    // this.initialized = true;
  }
}


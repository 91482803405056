import { createReducer, on } from '@ngrx/store';
import * as NavActions from '../actions/nav.action';

export interface NavState {
  menuOpen: boolean;
  searchActive: boolean;
  theme: string;
  hideLogo: boolean;
}

export const initialState: NavState = {
  menuOpen: false,
  searchActive: false,
  theme: 'palette-light',
  hideLogo: false,
};

export const NavReducer = createReducer(
  initialState,
  on(NavActions.OpenMenu, state => {
    return {
      ...state,
      menuOpen: true,
    };
  }),
  on(NavActions.CloseMenu, state => {
    return {
      ...state,
      menuOpen: false,
    };
  }),
  on(NavActions.ToggleMenu, state => {
    return {
      ...state,
      menuOpen: !state.menuOpen,
    };
  }),
  on(NavActions.OpenSearch, state => {
    return {
      ...state,
      menuOpen: true,
      searchActive: true,
    };
  }),
  on(NavActions.CloseSearch, state => {
    return {
      ...state,
      menuOpen: false,
      searchActive: false,
    };
  }),
  on(NavActions.SetTheme, (state, { theme }) => {
    return {
      ...state,
      theme,
    };
  }),
  on(NavActions.hideLogo, (state, { hide }) => {
    return {
      ...state,
      hideLogo: hide,
    };
  })
);

export const getMenuOpen = (state: NavState) => state.menuOpen;
export const getSearchActive = (state: NavState) => state.searchActive;
export const getTheme = (state: NavState) => state.theme;
export const getHideLogo = (state: NavState) => state.hideLogo;

import { Pipe, PipeTransform } from '@angular/core';
import * as truncate from 'lodash/truncate';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, max: number = 20): any {
    return truncate(value, { length: max });
  }
}

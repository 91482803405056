import { NgModule } from "@angular/core";

import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";

const material = [MatDialogModule, MatTooltipModule, MatSnackBarModule];

@NgModule({
  imports: [material],
  exports: [material]
})
export class MaterialModule {}

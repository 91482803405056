import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], filterField: any, filterVal: any): any {
    if (!value) {
      return value;
    }

    if (!filterField) {
      return value.filter(a => a === filterField);
    }

    return value.filter(a => a[filterField] === filterVal);
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-share-social',
  templateUrl: './share-social.component.html',
})
export class ShareSocialComponent implements OnInit {
  @Input() shareLinks = null;
  @Input() shareTitle = 'Deel dit artikel';
  title: any;
  titleService: any;
  constructor() {}

  ngOnInit() {}

  goToLink(url: string) {
    // console.log('Works');
    window.open(url, '_blank', 'toolbar=yes,top=225,left=500,width=700,height=500');
  }
}

import { createAction, props } from '@ngrx/store';

export const OpenMenu = createAction('[Nav] Open menu');
export const CloseMenu = createAction('[Nav] Close menu');
export const ToggleMenu = createAction('[Nav] Toggle menu');

export const SetTheme = createAction('[Nav] Set Theme', props<{ theme: string }>());
export const hideLogo = createAction('[Nav] Set Logo', props<{ hide: boolean }>());

export const OpenSearch = createAction('[Nav] Open search');
export const CloseSearch = createAction('[Nav] Close search');

import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() loading = false;
  @Input() title = 'Default title';
  @Input() subtitle;
  @Input() introtext;
  @Input() type;_
  @Input() bgImage;
  @Input() video = false;
  @Input() videoBg = false;
  @Input() cssClass = '';

  isVisible = false;

  initialPlayerVars = {
    autoplay: 1,
    controls: 0,
    rel: 0,
    mute: 1,
    showinfo: 0,
    modestbranding: 1
  };

  playerVars = {
    autoplay: 1,
    controls: 1,
    mute: 0,
    rel: 0,
    modestbranding: 1
  };

  playVideo = false;
  hideHeaderText = false;

  constructor(
  ) { }

  ngOnInit() {

  }

  playVideoClicked() {
    this.playVideo = true;
    this.hideHeaderText = true;
    this.videoBg = false;
  }

}

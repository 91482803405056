<div class="app">
  <div class="app__wrapper" [class.anim--in]="loaded$ | async">
    <app-topbar
      class="app__topbar"
      *ngIf="loaded$ | async"
      [auth]="auth"
      (signOut)="signOut()"
    ></app-topbar>
    <router-outlet class="app__router" *ngIf="loaded$ | async"></router-outlet>
    <app-footer class="app__footer" *ngIf="loaded$ | async"></app-footer>
  </div>
  <app-loader-indicator [loading]="loading$ | async"></app-loader-indicator>
</div>

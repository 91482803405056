import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof window !== 'undefined') {
      const element = document.createElement('div');
      element.innerHTML = value;
      return element.innerText;
    } else {
      return value;
    }
  }
}

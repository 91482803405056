import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppModule } from "../app.module";
import { StringJoinPipe } from "./pipes/stringjoin.pipe";
import { UrlRewritePipe } from "./pipes/urlrewrite.pipe";
import { LoaderIndicatorComponent } from "./components/loading-indicator/loading-indicator.component";
import { HeaderComponent } from "./components/header/header.component";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { IconComponent } from "./components/icon/icon.component";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { StripHtmlPipe } from "./pipes/strip-html.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { SearchPipe } from "./pipes/search.pipe";
import { SortByPipe } from "./pipes/sort-by.pipe";
import { SortByLengthPipe } from "./pipes/sort-by-length.pipe";
import { FooterComponent } from "./components/footer/footer.component";
import { YoutubeComponent } from "./components/youtube/youtube.component";
import { Html5VideoComponent } from "./components/html5video/html5video.component";
import { TeaserComponent } from "./components/teaser/teaser.component";
import { InViewportDirective } from "./directives/in-view.directive";
import { CountDirective } from "./directives/count.directive";
import { ConfirmComponent } from "./components/confirm/confirm.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { ShareService } from "./services/share.service";
import { ShareSocialComponent } from "./components/share-social/share-social.component";
import { DictionaryNgrxModule } from "@teamfoster/sdk/dictionary-ngrx";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";

const moduleExports = [
  StringJoinPipe,
  UrlRewritePipe,
  StripHtmlPipe,
  TruncatePipe,
  SortByPipe,
  SearchPipe,
  LoaderIndicatorComponent,
  HeaderComponent,
  TopbarComponent,
  IconComponent,
  ScrollToDirective,
  SortByLengthPipe,
  FooterComponent,
  YoutubeComponent,
  Html5VideoComponent,
  TeaserComponent,
  InViewportDirective,
  CountDirective,
  ConfirmComponent,
  FilterPipe,
  ShareSocialComponent,
  BreadcrumbsComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, DictionaryNgrxModule],
  declarations: [moduleExports],
  exports: [moduleExports],
  providers: [ShareService],
})
export class CoreModule {}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-teaser',
  templateUrl: 'teaser.component.html'
})
export class TeaserComponent implements OnInit {
  @Input() data;
  @Input() type = 'block';
  ngOnInit() {
    // console.log(this.data);
  }
}
